import React from 'react'

const Faq = () => {
  return (
    <>
      <div className="row_am faq_section" id='faqs'>
      <div className="faq_bg"> <img src="/images/section-bg.png" alt="image" /> </div>
        <div className='container'>
        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
          {/* <!-- h2 --> */}
          <h2 className='mb-5'><span>FAQ</span> - Frequently Asked Questions</h2>
          {/* <!-- p --> */}
          {/* <p>Lorem Ipsum is simply dummy text of the printing and typese tting <br> indus orem Ipsum has beenthe
            standard dummy.</p> */}
        </div>
          <div className='my-3'>
            <h4 className='fw-bold faq-head'>What should a Synthetic signal tell you?</h4>
            <p>A Synthetic signal should include an entry price, a stop loss, and a take profit for each trade. This
              way, you can control the risk-reward ratio of your trades and protect your account balance. If a
              signal has multiple take profit levels, you need to follow the signal’s instructions on how to exit
              the trade. For example, some signals may suggest closing part of your position at the first take
              profit and moving the stop loss to the entry price to secure your profits and reduce your risk.</p>
          </div>
          <div className='my-3'>
            <h4 className='fw-bold faq-head'>What are the different types of signals provided?</h4>
            <p>We provide different types of signals depending on the market conditions and the trading
              strategy. These are:</p>
            <ul>
              <li className='my-2'><b>Market execution trade signals:</b> These signals tell you to buy or sell a currency pair at
                the current market price.</li>
              <li className='my-2'><b>Buy Stop signals:</b>These signals tell you to place a pending order to buy a currency pair
                above the current market price, expecting the price to rise further.</li>
              <li className='my-2'><b>Sell Stop signals:</b> These signals tell you to place a pending order to sell a currency pair
                below the current market price, expecting the price to fall further.</li>
              <li className='my-2'><b>Buy Limit signals:</b> These signals tell you to place a pending order to buy a currency
                pair below the current market price, expecting the price to bounce back.</li>
              <li className='my-2'><b>Sell Limit signals:</b>These signals tell you to place a pending order to sell a currency pair
                above the current market price, expecting the price to drop.</li>
            </ul>
          </div>
          <div className='my-3'>
            <h4 className='fw-bold faq-head'>How are the signals generated? Is it fully automated or is there human intervention?</h4>
            <p>The signals are generated by AI, using advanced algorithms that analyze the market data and
              trends. There is no human intervention involved in the signal generation process. Our AI system
              is fully automated and reliable, and it can provide you with accurate and timely signals.
            </p>
          </div>
          <div className='my-3'>
            <h4 className='fw-bold faq-head'>How do I manage my risk when using the signals ?</h4>

            <p>Our signals give you three important pieces of information for each trade: the entry price, the
              stop loss, and the take profit.</p>
            <p>The entry price is where you should open the trade, either by buying or selling the currency pair.
              The stop loss is where you should close the trade if it goes against you, to limit your losses and
              protect your account balance.</p>
            <p>The take profit is where you should close the trade if it goes in your favor, to lock in your profits
              and exit the trade at the optimal point.</p>
            <p>You also need to decide how much to trade for each signal, which is called the lot size. The lot
              size depends on your account fund balance and your risk management strategy.</p>
          </div>
          <div className='my-3'>
            <h4 className='fw-bold faq-head'>What is the average profit/loss ratio of the signals ?</h4>
            <p>Our signals have a high accuracy rate of 75% to 82%, based on our past data. This means that
              most of our signals result in profitable trades for our users. The average profit/loss ratio of our
              signals is the ratio between the average profit and the average loss per trade. This ratio
              depends on various factors, such as the market conditions, the trading strategy, the risk
              management, and the lot size. However, we aim to provide signals that have a profit/loss ratio of
              at least 2:1, meaning that the average profit is twice as much as the average loss. This way, you
              can maximize your returns and minimize your risks</p>
          </div>
          <div className='my-3'>
            <h4 className='fw-bold faq-head'>How do you receive the signal ?</h4>
            <p>The AI signals are shared on a telegram channel. You can pin the channel and get notified when
              a new signal is posted. To get access to the channel,</p>
            <ul>
              <li>1. Go to the website “mysignal.ai” and click on the “<b>Join Now</b>” button. You will need to
                enter some basic information, such as your name and email.</li>
              <li className='my-3'>2. After you submit the details, you will be directed to join the telegram channel.</li>
            </ul>
          </div>
          <div className='my-3'>
            <h4 className='fw-bold faq-head'>How many signals are posted in a day on the Telegram channel?</h4>
            <p>In our free Telegram channel, you’ll receive one complimentary signal daily. For our VIP
channel, we provide up to three signals every day.. The goal is to provide quality signals,
not quantity. Only signals that have a high probability of success and a good risk-reward
ratio are sent.</p>
          </div>
          <div className='my-3'>
            <h4 className='fw-bold faq-head'> Can a beginner follow the signals ?</h4>
            <p>Yes, beginners can follow our trade signals. It just involves copy-pasting the price values into
              your trading terminal.</p>
          </div>
          <div className='my-3'>
            <h4 className='fw-bold faq-head'>How much is the forex signal fee ?</h4>
            <p>There are two Telegram signal channels: one is free, and the VIP channel costs $49 per
month.</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Faq