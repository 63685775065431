import logo from './logo.svg';
import './App.css';
import HeroSection from './Components/HeroSection';
import Features from './Components/Features';
import WhyPage from './Components/WhyPage';
import Testimonial from './Components/Testimonial';
import Pricing from './Components/Pricing';
import Joinnow from './Components/Joinnow';
import Footer from './Components/Footer';
import { useEffect, useState } from 'react';
import { checktoken, registerUser } from './api';
import Navbar from './Components/Navbar';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import toast, { Toaster } from 'react-hot-toast';
import { LiveChatWidget, EventHandlerPayload } from '@livechat/widget-react'
import Faq from './Components/Faq';

function App() {
  const [valid, setValid] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [email, setEmail] = useState("")
  const [userName, setUserName] = useState("")
  const [code, setCode] = useState("")

  useEffect(() => {
    const checkCode = async () => {
      if (code) {
        try {
          const { data } = await checktoken({ referral: code });
          console.log(data)
          if (data.status) {
            setValid(true)
          } else {
            setValid(false)
          }
        } catch (err) {
          setValid(false)
        }
      }
    }
    checkCode();
  }, [code])

  function handleNewEvent(event) {
    // console.log('LiveChatWidget.onNewEvent', event)
  }

  useEffect(() => {
    if (valid) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [valid])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: "320px",
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 99999999
    },
  };
  const closeModal = () => {
    setIsOpen(false)
  }
  const openModal = () => {
    setIsOpen(true)
  }
  const addUser = async () => {
    try {
      const { data } = await registerUser({ email, name: userName, referral: code })
      if (data.status) {
        setIsOpen(false)
        toast.success(data.message)
      } else {
        setIsOpen(false)
        toast.error(data.message)
      }
    } catch (err) {
      toast.error(err.message)
    }
  }
  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    let foo = params.get('referral');
    if (foo) {
      setCode(foo)
    }
  }, [])

  return (
    <>
      <style jsx>
        {`
        .faq-head {
          border-left:5px solid #6A49F2;
          padding: 0 0 0 12px;
      }
      
      .submitButton {
          background-color: #6A49F2 !important;
          border: none;
          color: white;
          padding: 10px ;
          text-align: center;
          display: inline-block;
          font-size: 16px;
          cursor: pointer;
      }
      .submitButton:hover {
          background-color: #35208b !important;
          color: white;
      }
      .close {
        position: absolute;
        top:15px;
        right:20px;
      }
      `}
      </style>
      <div className='overflow-hidden'>
        {/* <div class="page_wrapper"> */}
        <Navbar />
        {/* ========== Hero Section ========== */}
        <HeroSection />
        {/* =========== Features ============ */}
        <Features />
        {/* =========== Why signal.io ========= */}
        <WhyPage />
        {/* ============ Pricing ============ */}
        <Pricing />
        {/* ============ Testimoanils =========
        <Testimonial /> */}
        {/* =============== Faqs ============= */}
        <Faq />
        {/* ============ Join Now =========== */}
        <Joinnow />
        {/* ============ Footer =========== */}
        <Footer />
        {/* </div> */}
        <Modal show={isOpen} onHide={closeModal} backdrop="static" size='lg' centered>
          <Modal.Body>
            <h1 className='close' style={{cursor:"pointer"}} onClick={closeModal}>
              &times;
            </h1>
            <div className="row p-5 align-items-center">
              <div className="col-lg-6 my-2">
                <img src="/images/modal.svg" alt="crypto" />
              </div>
              <div className="col-lg-6 my-2">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    value={userName}
                    onChange={e => setUserName(e.target.value)}
                    type="text"
                    placeholder="Enter Name"
                    autoFocus
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    type="email"
                    placeholder="Enter Your email"
                    autoFocus
                  />
                </Form.Group>
                <button className='btn w-100 my-4 submitButton' style={{ backgroundColor: "#6A49F2" }} onClick={addUser} >Submit</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />
      <LiveChatWidget
        license="17198007"
        visibility="minimized"
        onNewEvent={handleNewEvent}
      />
    </>
  );
}

export default App;
