import React from 'react'

const Pricing = () => {
    return (
        <>
            {/* <!-- Pricing-Section --> */}
            <section className="row_am pricing_section" id="pricing">
                {/* <!-- container start --> */}
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                        {/* <!-- h2 --> */}
                        <h2>Best & simple pricing</h2>
                        {/* <!-- p --> */}
                    </div>

                    {/* <!-- pricing box  monthly start --> */}
                    <div className="pricing_pannel monthly_plan active" data-aos="fade-up" data-aos-duration="1500">
                        {/* <!-- row start --> */}
                        <div className="row justify-content-center">
                            {/* <!-- pricing box 1 --> */}
                            <div className="col-md-4">
                                <div className="pricing_block">
                                    <div className="icon">
                                        <img src="images/standard.png" alt="image"/ >
                                    </div>
                                    <div className="pkg_name">
                                        <h2>Free</h2>
                                        <span>For everyone</span>
                                    </div>
                                    <span className="price">$ 00<small className='h5'>/ Month</small></span>
                                    <ul className="benifits">
                                        <li>
                                            <p>One Synthetic trade signal daily</p>
                                        </li>
                                        <li>
                                            <p>-</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* <!-- pricing box 2 --> */}
                            <div className="col-md-4">
                                <div className="pricing_block highlited_block">
                                    <div className="icon">
                                        <img src="images/unlimited.png" alt="image" />
                                    </div>
                                    <div className="pkg_name">
                                        <h3>VIP Membership</h3>
                                        <span>For the professionals</span>
                                    </div>
                                    <span className="price">$ 49<small className='h5'>/ Month</small></span>
                                    <ul className="benifits">
                                        <li>
                                            <p>Synthetic and Gold Signals</p>
                                        </li>
                                        <li>
                                            <p>Up to 3 signals daily</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <!-- row end -->/ */}
                    </div>
                </div>
                {/* <!-- container start end --> */}
            </section>
            {/* <!-- Pricing-Section end --> */}
        </>
    )
}

export default Pricing