import React from 'react'

const HeroSection = () => {
    return (
        <>
            {/* // <!-- Banner-Section-Start --> */}
            <section className="banner_section">
                {/* <!-- hero bg --> */}
                <div className="hero_bg"> <img src="/images/hero-bg.png" alt="image" /> </div>
                {/* <!-- container start --> */}
                <div className="container">
                    {/* <!-- row start --> */}
                    <div className="row">
                        <div className="col-lg-6 col-md-12" data-aos="fade-right" data-aos-duration="1500">
                            {/* <!-- banner text --> */}
                            <div className="banner_text">
                                {/* <!-- h1 --> */}
                                <h1>Synthetic Signals: Your Gateway to <span> Expert Synthetic Trading Insights</span></h1>
                                {/* <!-- p --> */}
                                <p>Don’t miss this opportunity to join mysignal.ai and get the best Synthetic trading signals powered by
                                    AI for free. You will receive daily alerts that show you
                                </p>
                                <h6> 1. When to enter a trade</h6>
                                <h6> 2. When to exit a trade</h6>
                                <h6 className='mb-3'> 3. Where to set your stop-loss</h6>
                                <p>Start trading smarter with mysignal.ai today!</p>
                            </div>

                            <div className="trial_box">
                                {/* <!-- form --> */}
                                <form data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                                    <div className="form-group">
                                        <button className="btn">Join Now</button>
                                    </div>
                                </form>
                            </div>


                            {/* <!-- users --> */}
                            {/* <div className="used_app">
                                <ul>
                                    <li><img src="/images/used01.png" alt="image" /></li>
                                    <li><img src="/images/used02.png" alt="image" /></li>
                                    <li><img src="/images/used03.png" alt="image" /></li>
                                    <li><img src="/images/used04.png" alt="image" /></li>
                                </ul>
                                <p>12M + <br />Setisfied Members</p>
                            </div> */}
                        </div>

                        {/* <!-- banner images start --> */}
                        {/* <div className="col-lg-3 col-md-6" data-aos="fade-in" data-aos-duration="1500">
                            <div className="banner_images image_box1">
                                <span className="banner_image1"> <img className="moving_position_animatin" src="images/bannerimage1.png" alt="image" /> </span>
                                <span className="banner_image2"> <img className="moving_animation" src="images/bannerimage2.png" alt="image" /> </span>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6" data-aos="fade-in" data-aos-duration="1500">
                            <div className="banner_images image_box2">
                                <span className="banner_image3"> <img className="moving_animation" src="images/bannerimage3.png" alt="image" /> </span>
                                <span className="banner_image4"> <img className="moving_position_animatin" src="images/bannerimage4.png" alt="image" /> </span>
                            </div>
                        </div> */}
                        {/* <!-- banner slides end --> */}
                        <div className="col-lg-6">
                            <img src="/images/hero.svg" alt="hero" className='moving_animation img-fluid'/>
                        </div>

                    </div>
                    {/* <!-- row end --> */}
                </div>
                {/* <!-- container end --> */}
            </section>
            {/* <!-- Banner-Section-end --> */}
        </>
    )
}

export default HeroSection