import React from 'react'

const WhyPage = () => {
    return (
        <>
            <section className="row_am modern_ui_section" id='how_it_work'>
                {/* <!-- section bg --> */}
                <div className="modernui_section_bg"> <img src="images/section-bg.png" alt="image" /> </div>
                {/* <!-- container start --> */}
                <div className="container">
                    {/* <!-- row start --> */}
                    <div className="row">
                        <div className="col-lg-7">
                            {/* <!-- UI content --> */}
                            <div className="ui_text">
                                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                    <h2>Why <span>Synthetic Signals</span><br /> Stands Out</h2>
                                </div>
                                <ul className="design_block">
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <h4>Professional Mentors: </h4>
                                        <p>Receive guidance from experienced traders dedicated to your success.</p>
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <h4>Unlimited Learning Tools: </h4>
                                        <p>Access a wealth of professional learning materials created by trading experts.</p>
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <h4>82% Accurate Signals: </h4>
                                        <p>Real-time, highly accurate Synthetic trading signals, verified for profitability</p>
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <h4>Frequent Signals:</h4>
                                        <p>Our free Telegram channel offers one free signal per day, while our VIP channel delivers
                                            up to three signals daily.</p>
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <h4>Time-Efficient: </h4>
                                        <p>Let us handle complex analytics, making your trading journey hassle-free</p>
                                    </li>
                                    <li data-aos="fade-up" data-aos-duration="1500">
                                        <h4>Stable Gains: </h4>
                                        <p>Our goal is to deliver consistent monthly gains.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            {/* <!-- UI Image --> */}
                            <div className="ui_images" data-aos="fade-in" data-aos-duration="1500">
                                <div className="left_img">
                                    <img className="moving_position_animatin" src="/images/modern01.png" alt="image" />
                                </div>
                                {/* <!-- UI Image --> */}
                                <div className="right_img">
                                    <img className="moving_position_animatin" src="/images/shield_icon.png" alt="image" />
                                    <img className="moving_position_animatin" src="/images/modern02.png" alt="image" />
                                    <img className="moving_position_animatin" src="/images/modern03.png" alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- row end --> */}
                </div>
                {/* <!-- container end --> */}
            </section>
        </>
    )
}

export default WhyPage