import React from 'react'

const Navbar = () => {
  return (
    <>
        <header>
          {/* <!-- container start --> */}
          <div className="container">
            {/* <!-- navigation bar --> */}
            <nav className="navbar navbar-expand-lg">
              <a className="navbar-brand" href="#">
                <img src="/images/logo.png" alt="image" />
              </a>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon">
                  {/* <!-- <i className="icofont-navigation-menu ico_menu"></i> --> */}
                  <div className="toggle-wrap">
                    <span className="toggle-bar"></span>
                  </div>
                </span>
              </button>

              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item active">
                    <a className="nav-link" href="#">Home</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#features">Features</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#how_it_work">How it works</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#pricing">Pricing</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#faqs">Faqs</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link dark_btn" href="#getstarted">Join Now</a>
                  </li>
                </ul>
              </div>
            </nav>
            {/* <!-- navigation end --> */}
          </div>
          {/* <!-- container end --> */}
        </header>
    </>
  )
}

export default Navbar