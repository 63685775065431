import React from 'react'

const Footer = () => {
    return (
        <>
            {/* <!-- Footer-Section start --> */}
            <footer>
                {/* <!-- section bg --> */}
                <div className="footer_bg"> <img src="/images/section-bg.png" alt="image" /> </div>
                <div className="top_footer" id="contact">
                    {/* <!-- container start --> */}
                    <div className="container">
                        {/* <!-- row start --> */}
                        <div className="row justify-content-around">
                            {/* <!-- footer link 1 --> */}
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="abt_side">
                                    <div className="logo"> <img src="/images/logo.png" alt="image" /></div>
                                    <ul>
                                        <li><a href="#">hello@mysignal.ai</a></li>
                                        {/* <li><a href="#">+1-900-123 4567</a></li> */}
                                    </ul>
                                    {/* <ul className="social_media">
                                        <li><a href="#"><i className="icofont-facebook"></i></a></li>
                                        <li><a href="#"><i className="icofont-twitter"></i></a></li>
                                        <li><a href="#"><i className="icofont-instagram"></i></a></li>
                                        <li><a href="#"><i className="icofont-pinterest"></i></a></li>
                                    </ul> */}
                                </div>
                            </div>

                            {/* <!-- footer link 2 --> */}
                            <div className="col-lg-3 col-md-6 col-12">
                                <div className="links">
                                    <h3>Explore Site</h3>
                                    <ul>
                                        <li><a href="#">Home</a></li>
                                        <li><a href="#features">Features</a></li>
                                        <li><a href="#how_it_work">How it works</a></li>
                                        <li><a href="#pricing">Pricing</a></li>
                                        <li><a href="#faqs">Faqs</a></li>
                                    </ul>
                                </div>
                            </div>


                            {/* <!-- footer link 4 --> */}
                            <div className="col-lg-2 col-md-6 col-12">
                                <div className="try_out">
                                    <h3>Join Now</h3>
                                    <a href="#">
                                        <img src="/images/Button.png" alt="image" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- row end --> */}
                    </div>
                    {/* <!-- container end --> */}
                </div>

                {/* <!-- last footer --> */}
                <div className="bottom_footer">
                    {/* <!-- container start --> */}
                    <div className="container">
                        {/* <!-- row start --> */}
                        <div className="d-flex justify-content-center">
                            <div className="">
                                <p>© Copyrights 2022. All rights reserved.</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <div className="my-2 ">
                                <p className='text-center'>Trading in Forex, binary options, futures, and stocks presents the potential for
                                    significant rewards but comes with equally substantial risks. Before venturing into the stock,
                                    futures, and Forex markets, it is crucial to be aware of and willingly accept these risks. Never
                                    engage in trading with funds you cannot afford to lose, particularly when dealing with leveraged
                                    instruments such as binary options, futures, or Forex.
                                </p><p className='text-center'>
                                    Our website is not intended to solicit your participation in the Synthetic trade. We provide signals
                                    that highlight trades with higher chances of success; however, this is not to be construed as
                                    trading advice. Past performance does not guarantee future results when using the Synthetic signals
                                    we offer. The risk of losing all your invested capital is considerable and is influenced by factors
                                    such as adverse market conditions, errors, emotional responses, and unexpected events.</p>
                            </div>
                        </div>
                        {/* <!-- row end --> */}
                    </div>
                    {/* <!-- container end --> */}
                </div>

                {/* <!-- go top button --> */}
                <div className="go_top">
                    <span><img src="images/go_top.png" alt="image" /></span>
                </div>
            </footer>
            {/* <!-- Footer-Section end --> */}
        </>
    )
}

export default Footer