import React from 'react'

const Features = () => {
    return (
        <>
            {/* <!-- Features-Section-Start --> */}
            <section className="row_am features_section" id="features">
                {/* <!-- section bg --> */}
                <div className="feature_section_bg"> <img src="images/section-bg.png" alt="image" /> </div>
                {/* <!-- container start --> */}
                <div className="container">
                    <div className="features_inner">

                        {/* <!-- feature image --> */}
                        <div className="feature_img" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                            <img src="images/device-feature.png" alt="image" />
                        </div>

                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                            {/* <!-- h2 --> */}
                            <h2>Dive into the World of <span>Synthetic Trading</span> with Confidence</h2>
                            {/* <!-- p --> */}
                            <p>Join our Telegram community and access a suite of powerful trading signals, proven strategies, and professional guidance.</p>
                        </div>

                        {/* <!-- story --> */}
                        <div className="features_block">
                            <div className="row justify-content-around">
                                <div className="col-md-5">
                                    <div className="feature_box" data-aos="fade-up" data-aos-duration="1900">
                                        <div className="image">
                                            <img src="/images/support.png" alt="image" />
                                        </div>
                                        <div className="text">
                                            <h4>Free Access: </h4>
                                            <p>Connect with our Telegram channel, "Synthetic Signals," and start receiving daily trading alerts at no cost.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-5">
                                    <div className="feature_box" data-aos="fade-up" data-aos-duration="1500">
                                        <div className="image">
                                            <img src="/images/secure_data.png" alt="image" />
                                        </div>
                                        <div className="text">
                                            <h4>VIP Membership: </h4>
                                            <p>Elevate your trading with our VIP service. For just $49 per month, gain access to highly
                                                accurate signals boasting a 75% to 82% accuracy rate.   </p>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>

                    </div>

                </div>
                {/* <!-- container end --> */}
            </section>
            {/* <!-- Features-Section-end --> */}
        </>
    )
}

export default Features