import axios from 'axios';

const api = axios.create({
    baseURL:'https://mysignal.9dottechno.com',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
})

export const checktoken = (data) => api.post('/api/checkcode',data);
export const registerUser = (data) => api.post('/api/adduser',data);