import React from 'react'

const Joinnow = () => {
  return (
    <>
        {/* <!-- Download-Free-App-section-Start  --> */}
    <section className="row_am free_app_section" id="getstarted">
    	{/* <!-- container start --> */}
        <div className="container">
            <div className="free_app_inner" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100"> 
              	{/* <!-- row start --> */}
                <div className="row">
                	{/* <!-- content --> */}
                    <div className="col-md-6">
                        <div className="free_text">
                            <div className="section_title">
                                <h2>Ready to Transform Your Trading? Join the Synthetic Signals Community Today!</h2>
                                <p>Follow us on Telegram for regular trading signals.</p>
                                <p>Be part of a community of traders who support and learn from each other</p>
                            </div>
                            <ul className="app_btn">
                              
                              <li>
                                <a href="#">
                                  <img src="/images/Button.png" alt="image" />
                                </a>
                              </li>
                            </ul>
                        </div>
                    </div>

                    {/* <!-- images --> */}
                    <div className="col-md-6">
                        <div className="free_img">
                            <img src="images/download-screen01.png" alt="image" />
                            <img className="mobile_mockup" src="/images/download-screen02.png" alt="image"/ >
                        </div>
                    </div>
                </div>
                {/* <!-- row end --> */}
            </div>
        </div>
        {/* <!-- container end --> */}
    </section>
    {/* <!-- Download-Free-App-section-end  --> */}
    </>
  )
}

export default Joinnow